body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@tailwind base;

@tailwind components;

@tailwind utilities;

@layer base {
  h1 {
    font-size: 2.5rem; /* 32px */
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1;
  }

  h2 {
    font-size: 1.5rem; /* 24px */
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1;
  }

  h3 {
    font-size: 1.25rem; /* 20px */
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 1rem;
    line-height: 1;
  }

  h4 {
    font-size: 1rem; /* 16px */
    font-weight: 600;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    line-height: 1;
  }

  h5 {
    font-size: 0.875rem; /* 14px */
    font-weight: 600;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    line-height: 1;
  }

  h6 {
    font-size: 0.75rem; /* 12px */
    font-weight: 600;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    line-height: 1;
  }

  ul {
    list-style-type: disc;
    margin-left: 2rem;
    margin-bottom: 1rem;
  }

  ol {
    list-style-type: decimal;
    margin-left: 2rem;
    margin-bottom: 1rem;
  }

  li {
    margin-bottom: 0.25rem;
    font-size: 16px;
  }

  p {
    font-size: 16px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  div {
    font-size: 16px;
  }

  .container {
    max-width: 1400px;
  }
}
